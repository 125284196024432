import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selector", "submitter" ]

  connect() {
    let initialTimeElement = $(this.selectorTarget).find('input:checked')
    this.timeElement = initialTimeElement
  }

  selectInstallationTime(event) {
    let timeElement = $(event.target);
    this.timeElement = timeElement
  }

  get timeElement() {
    return this.data.get('selectedTime')
  }

  set timeElement(timeElement) {
    let selectedTime = timeElement.attr('data-time');
    this.data.set('selectedTime', selectedTime)

    $('.card.time-slot-selected').removeClass('time-slot-selected')
    timeElement.closest('.card').addClass('time-slot-selected')

    if (selectedTime) {
      $(this.submitterTarget).removeAttr("disabled")
    } else {
      $(this.submitterTarget).attr("disabled", true)
    }
  }
}
