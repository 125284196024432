import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selector", "submitter", "planPrice", "planId", "periodId", "servicePlan" ]

  connect() {
    let initialPlanElement = $(this.selectorTarget).find('input:checked')
    this.planElement = initialPlanElement
  }

  selectServicePlan(event) {
    let planElement = $(event.target);
    this.planElement = planElement
  }

  get planElement() {
    return this.data.get('selectedPlan')
  }

  set planElement(planElement) {
    let selectedPlan = planElement.attr('data-service-plan');
    let planPrice    = planElement.attr('data-plan-price');
    let planId       = planElement.attr('data-plan-id');
    let periodId     = planElement.attr('data-period-id');

    this.data.set('selectedPlan', selectedPlan)
    this.data.set('planPrice',    planPrice)
    this.data.set('planId',       planId)
    this.data.set('periodId',     periodId)

    if (selectedPlan) {
      $(this.submitterTarget).removeAttr("disabled")
      $(this.planPriceTarget).val(planPrice)
      $(this.periodIdTarget).val(periodId)
      $(this.planIdTarget).val(planId)
      $(this.servicePlanTarget).val(selectedPlan)
    } else {
      $(this.submitterTarget).attr("disabled", true)
    }
  }
}
