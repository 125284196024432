import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "selector", "businessCard", "companyName" ]

  connect() {
    let initialServiceSelection = $(this.selectorTarget).find('input:checked').attr('data-service-type')
    this.serviceSelection = initialServiceSelection
  }

  selectServiceType(event) {
    let serviceSelection = event.target.getAttribute('data-service-type');
    this.serviceSelection = serviceSelection
  }

  get serviceSelection() {
    return this.data.get('selectedService')
  }

  set serviceSelection(serviceSelection) {
    this.data.set('selectedService', serviceSelection)

    if (serviceSelection == 'residential') {
      $(this.businessCardTarget).hide()
      $(this.companyNameTarget).prop('required', false)

    } else if (serviceSelection == 'commercial') {
      $(this.businessCardTarget).show()
      $(this.companyNameTarget).prop('required', true)
    }
  }
}
