import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "selector",
    "otherCard",
    "otherType"
  ]

  connect() {
    let initialStructureSelection = $(this.selectorTarget).val()
    this.structureSelection = initialStructureSelection
  }

  selectStructureType(event) {
    let structureSelection = $(this.selectorTarget).val()
    this.structureSelection = structureSelection
  }

  get structureSelection() {
    return this.data.get('selectedStructure')
  }

  set structureSelection(structureSelection) {
    this.data.set('selectedStructure', structureSelection)

    if (structureSelection == 'other') {
      $(this.otherCardTarget).show()
      $(this.otherTypeTarget).prop('required', true)
    } else {
      $(this.otherCardTarget).hide()
      $(this.otherTypeTarget).prop('required', false)
    }
  }
}
