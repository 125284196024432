import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "selector",
    "serviceSelector",
    "structureCard", "landlordCard",
    "landOwnerName",
    "commercialOwnerCard",
    "commercialOnly",
    "yesThirdPartyManager",
    "thirdPartyManagerCard",
    "yesLeasesSpace",
    "leasingCard",
    "landlordName", "landlordEmail", "landlordPhone"
  ]

  connect() {
    let initialOwnershipSelection = $(this.selectorTarget).val()
    this.ownershipSelection = initialOwnershipSelection

    let initialServiceSelection = $(this.serviceSelectorTarget).find('input:checked').attr('data-service-type')
    this.serviceSelection = initialServiceSelection

    let hasThirdPartyManager = $(this.yesThirdPartyManagerTarget).is(':checked')
    this.hasThirdPartyManager = hasThirdPartyManager

    let leasesSpace = $(this.yesLeasesSpaceTarget).is(':checked')
    this.leasesSpace = leasesSpace
  }

  selectOwnershipType(event) {
    let ownershipSelection = $(this.selectorTarget).val()
    this.ownershipSelection = ownershipSelection
  }

  get ownershipSelection() {
    return this.data.get('selectedOwnership')
  }

  set ownershipSelection(ownershipSelection) {
    this.data.set('selectedOwnership', ownershipSelection)

    if (ownershipSelection == 'own') {
      if (this.serviceSelection == 'commercial') {
        $(this.commercialOwnerCardTarget).show()
      } else {
        $(this.commercialOwnerCardTarget).hide()
      }
      $(this.structureCardTarget).hide()
      $(this.landlordCardTarget).hide()
      $(this.landOwnerNameTarget).prop('required', false)
    } else if (ownershipSelection == 'structure') {
      if (this.serviceSelection == 'commercial') {
        $(this.structureCardTarget).hide()
        $(this.landlordCardTarget).show()
        $(this.landOwnerNameTarget).prop('required', false)
      } else {
        $(this.structureCardTarget).show()
        $(this.landlordCardTarget).hide()
        $(this.landOwnerNameTarget).prop('required', true)
      }
      $(this.commercialOwnerCardTarget).hide()
    } else if (ownershipSelection == 'rent') {
      $(this.commercialOwnerCardTarget).hide()
      $(this.structureCardTarget).hide()
      $(this.landlordCardTarget).show()
      $(this.landOwnerNameTarget).prop('required', false)
    } else {
      $(this.commercialOwnerCardTarget).hide()
      $(this.structureCardTarget).hide()
      $(this.landlordCardTarget).hide()
      $(this.landOwnerNameTarget).prop('required', false)
    }

    $(this.landlordNameTarget).prop('required', false)
    $(this.landlordEmailTarget).prop('required', false)
    $(this.landlordPhoneTarget).prop('required', false)
  }

  selectServiceType(event) {
    let serviceSelection = event.target.getAttribute('data-service-type');
    this.serviceSelection = serviceSelection

    this.selectOwnershipType()
  }

  get serviceSelection() {
    return this.data.get('selectedService')
  }

  set serviceSelection(serviceSelection) {
    this.data.set('selectedService', serviceSelection)

    if (serviceSelection == 'commercial') {
      $(this.commercialOnlyTargets).show()
    } else {
      $(this.commercialOnlyTargets).hide()
    }
  }

  selectHasThirdPartyManager(event) {
    this.hasThirdPartyManager = (event.target == this.yesThirdPartyManagerTarget)
  }

  get hasThirdPartyManager() {
    return this.data.get('hasThirdPartyManager')
  }

  set hasThirdPartyManager(hasThirdPartyManager) {
    this.data.set('hasThirdPartyManager', hasThirdPartyManager)

    if (hasThirdPartyManager) {
      $(this.thirdPartyManagerCardTarget).show()
    } else {
      $(this.thirdPartyManagerCardTarget).hide()
    }
  }

  selectLeasesSpace(event) {
    this.leasesSpace = (event.target == this.yesLeasesSpaceTarget)
  }

  get leasesSpace() {
    return this.data.get('leasesSpace')
  }

  set leasesSpace(leasesSpace) {
    this.data.set('leasesSpace', leasesSpace)

    if (leasesSpace) {
      $(this.leasingCardTargets).show()
    } else {
      $(this.leasingCardTargets).hide()
    }
  }
}
