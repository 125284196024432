// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("channels")
require('jquery')
require('jquery-input-mask-phone-number')


import 'bootstrap/dist/js/bootstrap'
import './debounce_forms'

import '../stylesheets/application'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const images2 = require.context('../../../engines/accounts/app/javascript/images', true)
const imagePath2 = (name) => images2(name, true)

import "controllers"

window.jQuery = $;
window.$ = $;

$(document).ready(function () {
  $('.phone-with-formatting').usPhoneFormat({
    format: 'xxx-xxx-xxxx',
  });
});
