import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["stripeUX", "plaidUX", "offlineUX", "paymentForm", "paymentMethod"]

  connect() {
    setTimeout(() => {
      let initialPaymentMethodSelection = $(this.paymentMethodTarget).find('input:checked').attr('data-payment-method')
      this.paymentMethodSelection = initialPaymentMethodSelection
    }, 1)
  }

  reportValidity(event) {
    if (!this.paymentFormTarget.reportValidity()) {
      event.preventDefault()
      event.stopPropagation()
    }
  }

  fetchPlaidToken(event) {
    const configs = {
      // Pass the link_token generated in step 2.
      token: this.data.get('plaidLinkToken'),
      onLoad: function() {
        // The Link module finished loading.
      },
      onSuccess: function(public_token, metadata) {
        // The onSuccess function is called when the user has
        // successfully authenticated and selected an account to
        // use.
        //
        // When called, you will send the public_token
        // and the selected account ID, metadata.accounts,
        // to your backend app server.
        //
        // sendDataToBackendServer({
        //   public_token: public_token,
        //   account_id: metadata.accounts[0].id
        // });
        document.getElementById("plaidPublicToken" ).value = public_token
        switch (metadata.accounts.length) {
          case 0:
            // Select Account is disabled: https://dashboard.plaid.com/link/account-select
            break;
          case 1:
            document.getElementById("plaidAccountId" ).value = metadata.accounts[0].id
            break;
          default:
            document.getElementById("plaidAccountId" ).value = metadata.accounts[0].id
            break;
          // Multiple Accounts is enabled: https://dashboard.plaid.com/link/account-select
        }
        if (metadata.accounts.length > 0) {
          document.getElementById("paymentForm").submit()
        }
      },
      onExit: async function(err, metadata) {
        // The user exited the Link flow.
        if (err != null) {
          // The user encountered a Plaid API error
          // prior to exiting.
          alert(err)
        }
        // metadata contains information about the institution
        // that the user selected and the most recent
        // API request IDs.
        // Storing this information can be helpful for support.
      },
    };

    var linkHandler = Plaid.create(configs);
    linkHandler.open()
  }

  get paymentMethodSelection() {
    return this.data.get('selectedPaymentMethod')
  }

  set paymentMethodSelection(paymentMethodSelection) {
    this.data.set('selectedPaymentMethod', paymentMethodSelection)

    if (paymentMethodSelection == 'credit_card') {
      $(this.stripeUXTarget).show()
      $(this.plaidUXTarget  ).hide()
      $(this.offlineUXTarget).hide()
    } else if (paymentMethodSelection == 'ach') {
      $(this.stripeUXTarget).hide()
      $(this.plaidUXTarget  ).show()
      $(this.offlineUXTarget).hide()
    } else if (paymentMethodSelection == 'offline') {
      $(this.stripeUXTarget).hide()
      $(this.plaidUXTarget  ).hide()
      $(this.offlineUXTarget).show()
    }
  }

  selectPaymentMethod(event) {
    let paymentMethodSelection = event.target.getAttribute('data-payment-method');
    this.paymentMethodSelection = paymentMethodSelection
  }
}
