import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "advancedOptionsCard", "offlineToggle", "onlineProvisioningCard", "offlineProvisioningCard",
                     "cardSelector", "portSelector", "deviceType", "profile", "card", "port"]

  toggleAdvanced() {
    if (this.advancedOptionsCardTarget.classList.contains('d-none')) {
      this.advancedOptionsCardTarget.classList.remove('d-none');
    } else {
      this.advancedOptionsCardTarget.classList.add('d-none');
    }
  }

  toggleOffline() {
    if (this.offlineToggleTarget.checked) {
      this.offlineProvisioningCardTarget.classList.remove('d-none');
      this.onlineProvisioningCardTarget.classList.add('d-none');
    } else {
      this.offlineProvisioningCardTarget.classList.add('d-none');
      this.onlineProvisioningCardTarget.classList.remove('d-none');
    }
  }

  changeDeviceProfileCard() {
    this.manageProfileOptions();

    if (this.profileTarget.value == 'Bridged') {
      if (this.deviceTypeTarget.value == 'XS-250WX-A') {
        this.cardSelectorTarget.classList.remove('d-none');
        if (this.cardTarget.value == 1) {
          this.portSelectorTarget.classList.remove('d-none');
        } else {
          this.portTarget.value = 1;
          this.portSelectorTarget.classList.add('d-none');
        }
      } else if (this.deviceTypeTarget.value == 'G-240W-E') {
        this.cardTarget.value = 1;
        this.cardSelectorTarget.classList.add('d-none');
        this.portSelectorTarget.classList.remove('d-none');
      } else if (this.deviceTypeTarget.value == 'G-2426G-B') {
        this.cardTarget.value = 1;
        this.cardSelectorTarget.classList.add('d-none');
        this.portSelectorTarget.classList.remove('d-none');
      } else {
        this.cardTarget.value = 10;
        this.portTarget.value = 1;
        this.cardSelectorTarget.classList.add('d-none');
        this.portSelectorTarget.classList.add('d-none');
      }
    } else {
      this.cardTarget.value = null;
      this.portTarget.value = null;
      this.cardSelectorTarget.classList.add('d-none');
      this.portSelectorTarget.classList.add('d-none');
    }
  }

  manageProfileOptions() {
    let disableRoutedOptions = (this.deviceTypeTarget.value == 'XS-010X-Q')
    let routedMatcher = /routed/i;
    let routedOptionElements =
      [...this.profileTarget.querySelectorAll('option')]
        .filter(optionElem => optionElem.innerText.match(routedMatcher))

    routedOptionElements.forEach(elem => {
      elem.disabled = disableRoutedOptions
    })

    let switchFromRouted = (disableRoutedOptions && this.profileTarget.value.match(routedMatcher))
    if (switchFromRouted) {
      let nonRoutedOption =
        [...this.profileTarget.querySelectorAll('option')]
          .find(optionElem => !optionElem.innerText.match(routedMatcher))
          .innerText
      this.profileTarget.value = nonRoutedOption
    }
  }
}
