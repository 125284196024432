import { Controller } from "stimulus"

const stepTargets = ["account", "service_plan", "expectations", "installation", "review", "payment", "confirmation"];

export default class extends Controller {
  static targets = [...stepTargets]

  connect() {
    this.configureButtons();
  }

  configureButtons() {
    // If the user is on the last step, "confirmation", then they may not go back,
    // because the sign up order has been placed and cannot be changed except by
    // calling customer service.
    let noBacksies = (this.currentStepName === 'confirmation')

    let finishedActivations = false
    for (var targetStepName of stepTargets) {
      let step_element = $(this.targets.find(targetStepName))
      let button_element = step_element.find('button').first()

      if (targetStepName === this.currentStepName) {
        finishedActivations = true

        step_element.addClass('current')
        step_element.removeClass('active')
        button_element.prop('disabled', false)
      } else if (finishedActivations || noBacksies) {
        step_element.removeClass('current')
        step_element.removeClass('active')
        button_element.prop('disabled', true)
      } else {
        step_element.removeClass('current')
        step_element.addClass('active')
        button_element.prop('disabled', false)
      }
    }
  }

  goToStep(event) {
    let targetStepName   = $(event.currentTarget).data('step');
    let targetStepIndex  = stepTargets.indexOf(targetStepName)
    let currentStepIndex = stepTargets.indexOf(this.currentStepName)
    let distance         = targetStepIndex - currentStepIndex

    if (distance == 0) {
      event.preventDefault()
    } else {
      window.history.go(distance)
    }
  }

  get currentStepName() {
    return this.data.get('currentStep')
  }
}
