// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

const context2 = require.context("../../../engines/accounts/app/javascript/controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context2))

const context3 = require.context("../../../engines/seranova/app/javascript/controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context3))

const context4 = require.context("../../../engines/ont/app/javascript/controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context4))
