import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["creditCardSelector", "bankAccountSelector", "creditCardInfo", "bankAccountInfo",
                    "stripePaymentForm", "stripeCardElement", "stripeSubmit", "stripeErrorElement",
                    "stripeSubmitText", "stripeSpinner"]

  connect() {
    setTimeout(() => {
      this.setUpStripe()
      this.updatePaymentCardVisibility()
    }, 1)
  }

  selectPaymentType(event) {
    this.updatePaymentCardVisibility()
  }

  updatePaymentCardVisibility() {
    if (this.creditCardSelectorTarget.checked) {
      this.creditCardInfoTarget.classList.remove('d-none')
      this.bankAccountInfoTarget.classList.add('d-none')
    } else {
      this.creditCardInfoTarget.classList.add('d-none')
      this.bankAccountInfoTarget.classList.remove('d-none')
    }
  }

  setUpStripe() {
    let stripe = Stripe(this.data.get("stripe-publishable-key"));
    let card   = this.mountCard(stripe);
    this.createStripePaymentIntent(stripe, card);
  }

  mountCard(stripe) {
    // Disable the button until we have Stripe set up on the page
    this.stripeSubmitTarget.disabled = true;

    let style = {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    let card = stripe.elements().create("card", {style: style});
    card.mount(this.stripeCardElementTarget);
    card.on("change", this.changeCard.bind(this));

    return card;
  }

  changeCard(event) {
    // Disable the Pay button if there are no card details in the Element
    this.stripeSubmitTarget.disabled = event.empty;
    this.stripeErrorElementTarget.textContent = event.error ? event.error.message : "";
  }

  createStripePaymentIntent(stripe, card) {
    let stripePurchaseData = JSON.stringify(JSON.parse(this.data.get("stripe-purchase-data")));

    fetch(this.data.get("create-stripe-payment-intent-path"),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: stripePurchaseData
      })
      .then(function (result) {
        return result.json();
      })
      .then(this.addSubmitHandler(stripe, card).bind(this));
  }

  addSubmitHandler(stripe, card) {
    return function (data) {
      let form = this.stripePaymentFormTarget;
      form.addEventListener("submit", this.handleSubmit(stripe, card, data).bind(this));
      document.getElementById("paymentIntentId" ).value = data.payment_intent_id
      document.getElementById("paymentIntentId2").value = data.payment_intent_id
    };
  }

  handleSubmit(stripe, card, data) {
    return function (event) {
      event.preventDefault();
      this.payWithCard(stripe, card, data.payment_intent_client_secret);
    };
  }

  payWithCard(stripe, card, clientSecret) {
    this.loading(true);

    stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: card
        }
      })
      .then(this.handlePaymentResult().bind(this));
  };

  handlePaymentResult() {
    return function (result) {
      if (result.error) {
        this.showError(result.error.message);
      } else {
        this.completeOrder(result.paymentIntent.id);
      }
    };
  }

  // Show the customer the error from Stripe if their card fails to charge
  showError(errorMsgText) {
    this.loading(false);

    let errorMsg = this.stripeErrorElementTarget;

    // Stripe uses the word "postal" instead of "zip code" in error messages,
    // which has the potential to confuse customers. So we replace "postal" here.
    let fixedErrorMsgText = errorMsgText.replace(/postal/i, 'zip code');

    errorMsg.textContent = fixedErrorMsgText;
    setTimeout(function() {
      errorMsg.textContent = "";
    }, 12000);
  };

  // Shows a success message when the payment is complete
  completeOrder(paymentIntentId) {
    this.loading(false);

    this.stripeSubmitTarget.disabled = true;
    document.getElementById("paymentForm").submit();
  };

  // Show a spinner on payment submission
  loading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      this.stripeSubmitTarget.disabled = true;
      this.stripeSpinnerTarget.classList.remove("hidden");
      this.stripeSubmitTextTarget.classList.add("hidden");
    } else {
      this.stripeSubmitTarget.disabled = false;
      this.stripeSpinnerTarget.classList.add("hidden");
      this.stripeSubmitTextTarget.classList.remove("hidden");
    }
  };
}
