import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "checkbox" ]

  verify(event) {
    if (this.hasCheckboxTarget && !this.checkboxTarget.checked) {
      window.alert(this.data.get('alert'))
      event.preventDefault()
      event.stopPropagation()
      event.stopImmediatePropagation()
    }
  }
}
